<template>
  <h2 class="text-[22px] font-bold pb-6 text-center leading-8">Track & Monitor<br />Your Credit Score</h2>
  <ion-text class="text-sm ion-text-grey text-center block w-[300px] mx-auto">
    Before you get started please read<br /> and accept the Consent{{ props.type == 'new' ? 's' : '' }}.
  </ion-text>
  <ion-icon src="/assets/credit-score/register-intro.svg" class="w-[345px] h-[220px] -mt-6 -mb-3 mx-auto block" />
  <div class="flex justify-between max-w-[345px] mx-auto pl-[45px] pr-[30px]">
    <ion-text class="text-xs ion-text-brand">Poor</ion-text>
    <ion-text class="text-xs ion-text-brand">Excellent</ion-text>
  </div>
  <div class="mx-auto mt-3 -mb-1 text-center">
    <ion-icon src="/assets/credit-score/register-x.svg" v-for="(_, i) in 3" :key="i"
      class="w-[35px] h-[45px] mx-[2px]" />
  </div>
  <ion-text class="text-sm ion-text-brand text-center block mx-auto mb-6">out of 1200</ion-text>

</template>
<script setup>
const props = defineProps(['type']);
</script>