<template>
  <ion-text class="text-md font-bold ion-text-brand">Have a question?</ion-text>
  <ion-text class="text-xs ion-text-purple mt-1 mb-6 block">Drop your details into this form and we'll get back to you
    as
    soon as we can.</ion-text>
  <ion-select interface="action-sheet" label="Subject" label-placement="floating"
    class="ion-text-purple pl-5 pr-3 mb-3 rounded-xl border text-sm" v-model="subject" required>
    <ion-select-option value="Application Status">Application Status</ion-select-option>
    <ion-select-option value="Update Payment Details">Update Payment Details</ion-select-option>
    <ion-select-option value="Update Profile">Update Profile</ion-select-option>
    <ion-select-option value="Others">Others</ion-select-option>
  </ion-select>
  <ion-textarea label-placement="floating" label="Question" class="ion-text-purple pl-5 pr-3 py-1 rounded-xl border text-sm"
    v-model="message"></ion-textarea>
  <ion-note slot="helper" class="w-full text-xs px-1 mt-1 ion-text-primary block" v-if="messageError">{{ messageError
    }}</ion-note>
  <button expand="block" color="secondary" class="button-purple button-icon button-icon-chat mt-3 mb-0"
    :disabled="!validated" @click="sendMsg(urls.apiContact)">
    <ion-spinner name="crescent" class="mx-auto my-2" v-if="btnClicked"></ion-spinner>
    <span class="block capitalize text-white text-[14px] font-semibold height-[60px]" v-else>Submit</span>
  </button>
  <hr class="my-6 hr-grey" />
  <ion-text class="text-md font-bold ion-text-brand">Contact Us</ion-text>
  <ion-text class="text-xs ion-text-purple mt-1 mb-6 block">Alternatively you can contact us on the details below:
  </ion-text>
  <contact-buttons></contact-buttons>
  <hr class="my-6 hr-grey" />
  <ion-text class="text-md font-bold ion-text-brand">Read our FAQs</ion-text>
  <ion-text class="text-xs ion-text-purple mt-1 mb-12 block">
    Need answers? Find out more on our <span class="ion-text-primary font-bold underline"
      @click="openBrowser(urls.faq)">FAQs</span>
  </ion-text>
  <ion-modal class="modal-center" ref="modal-contact-us-send" backdrop-dismiss="false" :is-open="status !== ''">
    <ion-card class="bg-white m-0 p-0 rounded-xl">
      <ion-card-content class="px-4 pt-3 pb-2">
        <ion-grid>
          <ion-row>
            <ion-col class="py-0 text-center">
              <ion-text class="ion-text-purple block font-bold text-sm">{{ sentTitle }}</ion-text>
            </ion-col>
            <ion-col class="py-0 text-right" size="1"></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-xs ion-text-brand my-3 text-center w-full">
              {{ sentMessage }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                @click="dismissModal()">
                <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
              </button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import ContactButtons from './ContactButtons.vue';
import { useBrowser } from '@/composables/browser';
import axios from 'axios';
import { modalController } from '@ionic/vue';
const customerStore = useCustomerStore();
const { profile, deviceID, environment, authToken } = storeToRefs(customerStore);
const { openBrowser, urls } = useBrowser();
const subject = ref('');
const message = ref('');
const messageError = computed(() => {
  if (message.value.length > 0 && message.value.length <= 10) {
    return 'The message must be at least 10 characters.';
  } else {
    return null;
  }
})
const validated = computed(() => subject.value && message.value.length > 10 && !btnClicked.value ? true : false);
const status = ref('');
const response = ref('');
const btnClicked = ref(false);
const sentTitle = computed(() => {
  if (status.value == 'success') {
    return 'Success'
  } else if (status.value == 'failed') {
    return 'Something Went Wrong';
  } else {
    return null;
  }
});
const sentMessage = computed(() => {
  if (status.value == 'success') {
    return 'Your question has been sent successfully.'
  } else if (status.value == 'failed') {
    return 'There was an error sending your question. Please contact us by phone or email.';
  } else {
    return null;
  }
});
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const sendMsg = (url) => {
  btnClicked.value = true;
  axios.post(url, {
    device_id: deviceID.value,
    environment: environment.value,
    id: profile.value.id,
    message: message.value,
    subject: subject.value
  }, headers)
    .then((res) => {
      if (res.status == 200) {
        status.value = 'success';
        response.value = res.data.message;
      }
    })
    .catch((err) => {
      status.value = 'failed';
      response.value = err.message;
    });
}
const resetMessage = () => {
  status.value = '';
  response.value = '';
  message.value = '';
  subject.value = '';
  btnClicked.value = false;
}
const dismissModal = () => {
  resetMessage();
  modalController.dismiss();
};
</script>