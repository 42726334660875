<template>
    <ion-page class="ion-page-white">
      <menu-header>
        <ion-text class="font-bold ion-text-brand block text-center">Debugging</ion-text>
      </menu-header>
      <ion-content>
        <div class="px-7 pt-6 max-w-xl mx-auto">
          <div>
            <pre>
                <small>
                    {{ debugging }}
                </small>
            </pre>
            
          </div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import MenuHeader from '@/components/menu/MenuHeader.vue';
  import { useConfigStore } from '@/stores';
  import { storeToRefs } from 'pinia';
  const configStore = useConfigStore();
  const { debugging } = storeToRefs(configStore);
  </script>