<template>
  <div class="typeaheader-wrapper bg-white">
    <ion-header class="ion-no-border bg-white" collapse="fade">
      <ion-toolbar color="white">
        <ion-buttons slot="start">
          <ion-button @click="cancelChanges()">
            <span class="ion-text-brand">Cancel</span>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <ion-text class="font-bold ion-text-brand block text-center">{{ $props.title }}</ion-text>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="confirmChanges()">
            <span class="ion-text-brand">Done</span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div class="px-1 mb-1">
        <ion-searchbar @ionInput="searchbarInput($event)" class="ion-text-brand"></ion-searchbar>
      </div>
    </ion-header>

    <ion-content color="light" class="ion-padding bg-white fadein-100">
      <ion-list :inset="true" class="-mt-1 bg-light">
        <ion-item class="default" lines="none" v-for="item in filteredItems" :key="item.name">
          <div class="absolute">
            <ion-checkbox :label="item.name" :value="item.name" :checked="isChecked(item.name)"
            @ionChange="checkboxChange($event)" class="w-[24px] ml-3"></ion-checkbox>
          </div>
          <ion-text class="ion-text-brand text-sm ml-12">{{ item.name }}</ion-text>
        </ion-item>
      </ion-list>
    </ion-content>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps(['items', 'selectedItems', 'title']);
const emit = defineEmits(['selection-cancel', 'selection-change']);
const filteredItems = ref(props.items);
const selectedValues = ref([...props.selectedItems]);
const isChecked = (value) => selectedValues.value.find((item) => item === value) !== undefined;
const cancelChanges = () => { emit('selection-cancel') };
const confirmChanges = () => { emit('selection-change', selectedValues.value) };
const searchbarInput = (ev) => { filterList(ev.target.value) };

const filterList = (searchQuery) => {
  if (searchQuery === undefined) {
    filteredItems.value = props.items;
  } else {
    const normalizedQuery = searchQuery.toLowerCase();
    filteredItems.value = props.items.filter((item) => item.name.toLowerCase().includes(normalizedQuery));
  }
};

const checkboxChange = (ev) => {
  const { checked, value } = ev.detail;
  selectedValues.value = checked ? [value] : selectedValues.value.filter((item) => item !== value);
};
</script>