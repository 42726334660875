<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header start="true"></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Create Account</h2>
        <register-steps step="1"></register-steps>
        <div class="flex items-center justify-between">
          <div class="ion-item-wrapper mr-4">
            <ion-select interface="action-sheet" placeholder="Title" class="ion-text-brand pl-2 pr-4 min-h-[55px]"
              v-model="title" required>
              <ion-select-option value="Mr">Mr</ion-select-option>
              <ion-select-option value="Mrs">Mrs</ion-select-option>
              <ion-select-option value="Miss">Miss</ion-select-option>
              <ion-select-option value="Ms">Ms</ion-select-option>
            </ion-select>
          </div>
          <div class="ion-item-wrapper ion-item-wrapper-v7 grow" :class="firstNamePrefill ? 'item-has-prefill' : ''">
            <ion-item class="default" lines="none">
              <ion-input label-placement="floating" label="First Name" v-model="firstName" @ionBlur="sanitizeInput" required></ion-input>
            </ion-item>
          </div>
        </div>
        <div class="ion-item-wrapper ion-item-wrapper-v7 my-4" :class="middleNamePrefill ? 'item-has-prefill' : ''">
          <ion-item class="default" lines="none">
            <ion-input label-placement="floating" label="Middle Name (optional)" v-model="middleName" @ionBlur="sanitizeInput" required></ion-input>
          </ion-item>
        </div>
        <div class="ion-item-wrapper ion-item-wrapper-v7 my-4" :class="surnamePrefill ? 'item-has-prefill' : ''">
          <ion-item class="default" lines="none">
            <ion-input label-placement="floating" label="Surname" v-model="surname" @ionBlur="sanitizeInput" required></ion-input>
          </ion-item>
        </div>
        <ion-note slot="helper" class="w-full text-xs px-1 mb-3 block ion-text-red">{{ validateError }}</ion-note>
        <ion-text class="text-sm ion-text-grey mb-6 text-center block">
          Before you get started please read and accept the Privacy Policy.
          <span class="ion-text-primary text-xs text-center underline" @click="openBrowser(urls.privacy)">
            Click here to view web version
          </span>
        </ion-text>
        <button class="px-2 button-purple" @click="updateAccepted">
          <span
            class="flex items-center justify-between text-white text-left pl-1 md:pl-3 text-[11px] sm:text-[10px] md:text-xs tracking-tight md:tracking-normal">
            I have read & accept the Privacy Policy and consent to be contacted for direct
            marketing purposes.
            <img :src="imgCheck" class="ml-1 md:ml-2" />
          </span>
        </button>
        <button class="button-purple button-register mt-4 px-6" :disabled="!accepted || !entered"
          @click="btnNext('register-step-2')">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold"
            :class="accepted && entered ? 'text-white' : 'text-primary'" v-else> Next <img :src="imgNext"
              class="float-right ml-2 mt-2" /></span>
        </button>
      </div>
      <div class="text-xs text-center mb-3  w-full">
        <span class="ion-text-grey">Had a loan before, or already have an account? </span>
        <button fill="clear" @click="$router.push('login-email')">
          <span class="ion-text-primary font-bold underline">Log In</span>
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import { useRegisterStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { useMixed } from '@/composables/mixed';
import { storeToRefs } from 'pinia';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import axios from 'axios';
const registerStore = useRegisterStore();
const { openBrowser, urls } = useBrowser();
const { formatName } = useMixed();
const { setTitle, setFirstName, setMiddleName, setSurname, setStepOneCompleted } = registerStore;
const stepOneState = storeToRefs(registerStore);
const title = ref('');
const titlePrefill = ref(false);
const firstName = ref('');
const firstNamePrefill = ref(false);
const middleName = ref('');
const middleNamePrefill = ref(false);
const surname = ref('');
const surnamePrefill = ref(false);
const entered = computed(() => title.value !== '' && firstName.value.trim() !== '' && surname.value.trim() !== '');
const imgCheck = computed(() => !accepted.value ? '/assets/register/register-check.svg' : '/assets/register/register-check-active.svg');
const imgNext = computed(() => accepted.value && entered.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg')
const accepted = ref(false);
const validated = ref(false);
const validateError = ref('');
const loading = ref(false);
const updateAccepted = () => {
  accepted.value = !accepted.value;
}
const sanitizeInput = () => {
  firstName.value = formatName(firstName.value);
  middleName.value = formatName(middleName.value);
  surname.value = formatName(surname.value);
  validateError.value = '';
}
const btnNext = (next) => {
  loading.value = true;
  axios.post(urls.apiSignUpStepNameVerify, {
    title: title.value,
    first_name: firstName.value,
    other_given_name: middleName.value,
    last_name: surname.value
  }).then(res => {
    if (res.status == 200) {
      setTitle(title.value);
      setFirstName(firstName.value);
      setMiddleName(middleName.value);
      setSurname(surname.value);
      setTimeout(() => {
        router.push(next);
        setStepOneCompleted(true);
        loading.value = false;
        validated.value = true;
      }, 1000);
    }
  }).catch(err => {
    loading.value = false;
    validated.value = false;
    validateError.value = err.response.data.message;
  });
}
const prefillCheck = () => {
  if (stepOneState.title.value) {
    title.value = stepOneState.title.value;
    titlePrefill.value = true;
  }
  if (stepOneState.firstName.value) {
    firstName.value = stepOneState.firstName.value;
    firstNamePrefill.value = true;
  }
  if (stepOneState.middleName.value) {
    middleName.value = stepOneState.middleName.value;
    middleNamePrefill.value = true;
  }
  if (stepOneState.surname.value) {
    surname.value = stepOneState.surname.value;
    surnamePrefill.value = true;
  }
}
onMounted(() => {
  prefillCheck();
  trackEvent(AdjustEvents.REGISTRATION_STARTED)
})
</script>
<style scoped>
ion-select::part(placeholder){
  color: var(--ion-color-text-grey);
  font-size: 13px;
  opacity: 0.5;
  width: fit-content;
  text-align: left;
}
</style>