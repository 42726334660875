import { PushNotifications } from "@capacitor/push-notifications";
import { App as CapacitorApp } from "@capacitor/app";
import router from "@/router";
import { useLoansStore, useCustomerStore } from "@/stores";
import { storeToRefs } from "pinia";

export function usePushNotifications() {
  const subscribePushNotificationRegistration = async (callback) => {
    await PushNotifications.addListener("registration", callback);
  };

  const registerPushNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }
    await PushNotifications.register();
  };

  const addPushNotificationsListeners = async () => {
    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value);
    });
    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });
    await PushNotifications.addListener("pushNotificationReceived", (n) => {
      console.log("Push notification received: ", n);
    });
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (n) => {
        let link = n.notification.data?.link;
        if (link) {
          handleDeeplink(link);
        }
      }
    );
    // for appUrlOpen event from browser
    CapacitorApp.addListener("appUrlOpen", (e) => {
      if (e.url) {
        handleDeeplink(e.url);
      }
    });
  };

  const getDeliveredNotifications = async () => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log("delivered notifications", notificationList);
  };

  const handleDeeplink = (link) => {
    const loansStore = useLoansStore();
    const customerStore = useCustomerStore();
    const { filterLoan, setModalPaymentOverdueOpen } = loansStore;
    const { loanSelected } = storeToRefs(loansStore);
    const { creditScoreEnrolled } = storeToRefs(customerStore);
    const { setApplyUrl } = customerStore;
    setModalPaymentOverdueOpen(false);
    setTimeout(() => {
      const url = [...link.split(/app.jacarandafinance.com.au|app.j-f.au/)];
      const slug = url.length > 1 ? url.pop() : '';
      const searchParams = slug.includes('?') ? Object.fromEntries(new URLSearchParams(slug.split('?')[1])) : null;
      const loanId = slug.includes("/loans") ? Number(slug.split("/")[2]) : null;
      switch (true) {
        case slug.includes("/loans"):
          try {
            filterLoan(loanId);
            const overdue = loanSelected.value
              ? loanSelected.value[0].overdue
              : null;
            router.push({ name: "Loan", params: { id: loanId } });
            setTimeout(() => {
              setModalPaymentOverdueOpen(!!overdue);
            }, 3000);
          } catch (err) {
            if (err) {
              router.push({ name: "Home" });
            }
          }
          break;
        case ['/finance-fundamentals', '/ff'].some(s => slug.includes(s)):
          router.push({ name: "FinanceFundamentalsIntro" });
          break;
        case ['/credit-score-register', '/cs'].some(s => slug.includes(s)):
          router.push({
            name: creditScoreEnrolled.value
              ? "CreditScore"
              : "CreditScoreRegister",
          });
          break;
        case ['/get-started-now', '/apply'].some(s => slug.includes(s)):
          setApplyUrl(searchParams);
          router.push({ name: "GetStartedNow" });
          break;
        case slug.includes("/profile"):
          router.push({ name: "Profile" });
          break;
        case slug.includes("/change-password"):
          router.push({ name: "ChangePassword" });
          break;
        case slug.includes("/contact"):
          router.push({ name: "Contact" });
          break;
        default:
          router.push({ name: "Home" });
          break;
      }
    }, 1000);
  };

  return {
    subscribePushNotificationRegistration,
    registerPushNotifications,
    addPushNotificationsListeners,
    getDeliveredNotifications,
  };
}
