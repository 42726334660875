<template>
  <ion-grid>
    <credit-score-id-form-header />
    <credit-score-retry-msg v-if="idCheckErrRetryCount == 1 || errors"></credit-score-retry-msg>
    <credit-score-id-form-buttons :buttons="buttons" @toggleSection="toggleSection" />
    <credit-score-id-form-card title="Driver's Licence" section="section-drivers-licence"
      :condition="isDriversLicenceProvided" @toggleSection="toggleSection('drivers-licence')">
      <div class="grow mb-4">
        <credit-score-id-form-item custom-class="ion-item-wrapper-select">
          <ion-select label-placement="floating" label="Driver's licence state" interface="action-sheet" class="ion-text-brand p-0 pr-4 min-h-[55px]"
            v-model="driversLicenceState">
            <ion-select-option v-for="[key, value] in licenceStates" :key="key" :value="key">
              {{ value }}
            </ion-select-option>
          </ion-select>
        </credit-score-id-form-item>
        <error-msg :error="validateInput('drivers_licence_state')" />
      </div>
      <div class="grow mb-4">
        <credit-score-id-form-item >
          <ion-input label-placement="floating" label="Driver's licence number" v-model="driversLicenceNumber" />
        </credit-score-id-form-item>
        <error-msg :error="validateInput('drivers_licence_number')" />
        <helper-text :helper="licenceNumberHelperText" />
      </div>
      <div class="grow">
        <credit-score-id-form-item >
          <ion-input label-placement="floating" label="Driver's licence card number" v-model="driversLicenceCardNumber" />
        </credit-score-id-form-item>
        <error-msg :error="validateInput('drivers_licence_card_number')" />
        <helper-text :helper="cardNumberHelperText" />
      </div>
      <img :src="sampleDriversLicence" class="mt-6 w-full" v-if="sampleDriversLicence">
    </credit-score-id-form-card>

    <credit-score-id-form-card title="Passport" section="section-passport" :condition="isPassportProvided"
      @toggleSection="toggleSection('passport')">
      <div class="grow mb-4">
        <credit-score-id-form-item>
          <ion-input label-placement="floating" label="Passport number" v-model="passportNumber" @change="forceUppercase" />
        </credit-score-id-form-item>
        <error-msg :error="validateInput('passport_number')" />
        <helper-text :helper="passportNumberHelperText" />
      </div>
      <div class="grow mb-4">
        <credit-score-id-form-item custom-id="select-passport-country">
          <ion-input label-placement="floating" label="Passport country" v-model="passportCountry" aria-readonly="true" />
        </credit-score-id-form-item>
        <ion-modal trigger="select-passport-country" ref="modal">
          <credit-score-typeahead class="ion-page" title="Passport Country" :items="countriesList"
            :selectedItems="[passportCountry]" @selection-change="countrySelectionChanged($event)"
            @selection-cancel="modal.$el.dismiss()"></credit-score-typeahead>
        </ion-modal>
        <error-msg :error="validateInput('passport_country')" />
      </div>
      <img :src="samplePassport" class="mt-6 w-full">
    </credit-score-id-form-card>

    <credit-score-id-form-card title="Medicare Card" section="section-medicare-card" :condition="isMedicareCardProvided"
      @toggleSection="toggleSection('medicare-card')">
      <div class="grow mb-4">
        <credit-score-id-form-item>
          <ion-input label-placement="floating" label="Medicare card number" v-model="medicareCardNumber" />
        </credit-score-id-form-item>
        <error-msg :error="validateInput('medicare_card_number')" />
        <helper-text :helper="medicareCardNumberHelperText" />
      </div>
      <div class="grow mb-4">
        <credit-score-id-form-item>
          <ion-input label-placement="floating" label="Medicare individual reference number" v-model="medicareIndividualReferenceNumber" />
        </credit-score-id-form-item>
        <error-msg :error="validateInput('medicare_card_ref_number')" />
        <helper-text :helper="medicareRefNumberHelperText" />
      </div>
      <div class="grow mb-4">
        <credit-score-id-form-item custom-id="open-modal-datetime-medicare">
          <ion-input label-placement="floating" label="Medicare card expiry date" v-model="medicareCardExpiryDateCustom" />
        </credit-score-id-form-item>
        <ion-modal class="modal-center modal-fit" ref="modal-datetime-medicare" trigger="open-modal-datetime-medicare"
          backdrop-dismiss="false">
          <ion-card class="bg-white m-0 p-0 rounded-xl fadein-500">
            <ion-card-content class="m-0 p-0">
              <ion-grid>
                <ion-row>
                  <div class="w-full text-right -mb-12">
                    <ion-icon src="/assets/general/close-grey.svg"
                      class="m-3 mb-0 w-[24px] h-[24px] cursor-pointer opacity-80 hover:opacity-100"
                      @click="closeModal"></ion-icon>
                  </div>
                </ion-row>
                <ion-row>
                  <div class="flex items-center justify-center my-4 w-full">
                    <ion-icon src="/assets/credit-score/icon-calendar.svg" class="w-[16px] pr-2"></ion-icon>
                    <ion-text class="text-lg font-bold ion-text-brand text-center">Select Date</ion-text>
                  </div>
                  <ion-text class="text-xs ion-text-gray text-center w-full mb-4 px-6"
                    v-if="medicareExpiryDateInstruction">
                    {{ medicareExpiryDateInstruction }}
                  </ion-text>
                  <ion-text class="text-sm text-center w-full mb-2" v-if="selectedExpiryDate">
                    You have selected:
                    <strong class="text-md ion-text-purple-3">
                      {{ formatCustomExpiryDate(selectedExpiryDate) }}
                    </strong>
                  </ion-text>
                </ion-row>
                <hr class="my-3" />
                <ion-row>
                  <ion-datetime presentation="month-year" :yearValues="getExpiryYears()" :prefer-wheel="true"
                    id="datetime" v-model="selectedExpiryDate" @ionChange="updateExpiryDate"
                    class="mx-auto scale-90 min-w-[300px]" color="brand"></ion-datetime>
                </ion-row>
                <hr class="my-3" />
                <ion-row>
                  <ion-col>
                    <div class="flex justify-center items-center mx-auto">
                      <div class="w-[150px] m-1">
                        <button color="secondary" class="button-purple button-purple-md" :disabled="!selectedExpiryDate"
                          @click="closeModal">
                          <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">Done</span>
                        </button>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-modal>
        <error-msg :error="validateInput('medicare_card_expiry')" />
        <helper-text :helper="medicareExpiryDateHelperText" />
      </div>
      <img :src="sampleMedicareCard" class="mt-6 w-full">
    </credit-score-id-form-card>

    <ion-row v-if="fieldsRequired">
      <ion-col>
        <button class="button-green button-shadow mb-8" @click="nextStep">
          <span class="block capitalize text-white text-[14px] font-semibold">Continue</span>
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import router from '@/router';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useCustomerStore, useConfigStore, useRegisterStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { useMixed } from '@/composables/mixed';
import CreditScoreIdFormHeader from '@/components/creditScore/CreditScoreIDFormHeader.vue';
import CreditScoreIdFormButtons from '@/components/creditScore/CreditScoreIDFormButtons.vue';
import CreditScoreIdFormCard from '@/components/creditScore/CreditScoreIDFormCard.vue';
import CreditScoreIdFormItem from '@/components/creditScore/CreditScoreIDFormItem.vue';
import CreditScoreRetryMsg from '@/components/creditScore/CreditScoreRetryMsg.vue';
import CreditScoreTypeahead from './CreditScoreTypeahead.vue';
import ErrorMsg from '@/components/misc/ErrorMsg.vue';
import HelperText from "@/components/misc/HelperText.vue";
import { modalController } from '@ionic/vue';
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const registerStore = useRegisterStore();
const { deviceID, environment, authToken, idCheckErrRetryCount } = storeToRefs(customerStore);
const { config } = storeToRefs(configStore);
const { creditScoreIdVerification } = storeToRefs(registerStore);
const { setCreditScoreIdVerification } = registerStore;
const { formatExpiryDate, formatCustomExpiryDate, getExpiryYears, scrollToBottom } = useMixed();
const { urls } = useBrowser();
const errors = ref(null);
const isDriversLicenceProvided = ref(false);
const isPassportProvided = ref(false);
const isMedicareCardProvided = ref(false);
const driversLicenceState = ref(null);
const driversLicenceNumber = ref(null);
const driversLicenceCardNumber = ref(null);
const passportDefault = 'Australia';
const passportNumber = ref(null);
const passportCountry = ref(passportDefault);
const medicareCardNumber = ref(null);
const medicareIndividualReferenceNumber = ref(null);
const medicareCardExpiryDate = ref(null);
const medicareCardExpiryDateCustom = ref(null);
const selectedExpiryDate = ref(null);
const buttons = computed(() => ([
  {
    label: 'Driver\'s Licence',
    section: 'drivers-licence',
    condition: isDriversLicenceProvided.value
  },
  {
    label: 'Passport',
    section: 'passport',
    condition: isPassportProvided.value
  },
  {
    label: 'Medicare Card',
    section: 'medicare-card',
    condition: isMedicareCardProvided.value
  }
]));
const toggleSection = (type) => {
  switch (type) {
    case 'drivers-licence':
      isDriversLicenceProvided.value = !isDriversLicenceProvided.value;
      goToSection(type, isDriversLicenceProvided.value);
      clearSection([driversLicenceState, driversLicenceNumber, driversLicenceCardNumber], isDriversLicenceProvided.value);
      break;
    case 'passport':
      isPassportProvided.value = !isPassportProvided.value;
      goToSection(type, isPassportProvided.value);
      clearSection([passportNumber], isPassportProvided.value);
      passportCountry.value = passportDefault;
      break;
    case 'medicare-card':
      isMedicareCardProvided.value = !isMedicareCardProvided.value;
      goToSection(type, isMedicareCardProvided.value);
      clearSection([medicareCardNumber, medicareIndividualReferenceNumber, medicareCardExpiryDate], isMedicareCardProvided.value);
      break;
  }
};

const goToSection = (id, provided) => {
  if (provided) {
    scrollToBottom(`section-${id}`);
  }
};

const clearSection = (group, provided) => {
  errors.value = null;
  if (!provided) {
    group.map(item => {
      item.value = null;
    });
  }
}

const cardNumberHelperText = computed(() => {
  const instructions = new Map([
    ['ACT', 'This number runs vertically alongside the photo on the licence.'],
    ['NT', 'This number can be found on the back of the licence if issued after Nov 2020 or on the front of the licence if issued before Nov 2020.'],
    ['QLD', 'This number can be found in the bottom middle of the front of your licence and the bottom middle of the back.'],
    ['NSW', 'This number can be found at the top right on the front of your licence.'],
    ['SA', 'This number can be found at the top right on the back of your licence.'],
    ['TAS', 'This number can be found in the top right on the back of your licence.'],
    ['VIC', 'This number can be found in the top right of the back of your licence.'],
    ['WA', 'This number can be found on the back of the licence.'],
  ])
  return instructions.get(driversLicenceState.value);
});

const licenceNumberHelperText = computed(() => {
  const instructions = new Map([
    ['ACT', 'This number can be found in the middle left of the front of your licence.'],
    ['NT', 'This number can be found in the middle or top right of the front of your licence.'],
    ['QLD', 'This number can be found in the top right of the front of your licence.'],
    ['NSW', 'This number can be found in the middle left of the front of your licence.'],
    ['SA', 'This number can be found in the top left of the front of your licence.'],
    ['TAS', 'This number can be found in the top left of the front of your licence.'],
    ['VIC', 'This number can be found in the top right of the front of your licence.'],
    ['WA', 'This number can be found in the top right of the front of your licence.'],
  ])
  return instructions.get(driversLicenceState.value);
});

const licenceStates = new Map([
  ['ACT', 'Australian Capital Territory'],
  ['NT', 'Northern Territory'],
  ['QLD', 'Queensland'],
  ['NSW', 'New South Wales'],
  ['SA', 'South Australia'],
  ['TAS', 'Tasmania'],
  ['VIC', 'Victoria'],
  ['WA', 'Western Australia'],
]);

const sampleDriversLicence = computed(() => driversLicenceState.value ? `/assets/credit-score/licence/circled_${driversLicenceState.value}.jpg` : null);
const samplePassport = computed(() => `/assets/credit-score/passport/sample.jpg`);
const sampleMedicareCard = computed(() => `/assets/credit-score/medicare/sample.jpg`);
const passportNumberHelperText = computed(() => `This can be found on the main page, in the top right.`);
const medicareCardNumberHelperText = computed(() => `This can be found above your name, across the top section of the card.`);
const medicareRefNumberHelperText = computed(() => `This number can be found beside your name.`);
const medicareExpiryDateHelperText = computed(() => `This can be found in the bottom right of your card.`);
const medicareExpiryDateInstruction = computed(() => !Capacitor.isNativePlatform() ? 'You can scroll your mouse wheel, or swipe on your track pad, or use arrow keys on your keyboard' : null);

const fieldsRequired = computed(() => {
  const isProvided = isDriversLicenceProvided.value || isPassportProvided.value || isMedicareCardProvided.value;
  const driversLicenceRequired = isDriversLicenceProvided.value ? driversLicenceState.value && driversLicenceNumber.value && driversLicenceCardNumber.value : true;
  const passportRequired = isPassportProvided.value ? passportNumber.value && passportCountry.value : true;
  const medicareRequired = isMedicareCardProvided.value ? medicareCardNumber.value && medicareIndividualReferenceNumber.value && medicareCardExpiryDate.value && medicareCardExpiryDateCustom.value : true;
  return isProvided && driversLicenceRequired && passportRequired && medicareRequired;
});

const updateExpiryDate = () => {
  medicareCardExpiryDate.value = formatExpiryDate(selectedExpiryDate.value);
  medicareCardExpiryDateCustom.value = formatCustomExpiryDate(selectedExpiryDate.value);
}

const modal = ref();
const countriesList = ref(null);
const formatData = (data) => {
  if (data.length === 1) {
    const country = countriesList.value.find((country) => country.name === data[0]);
    return country.name;
  }
  return ``;
};

const countrySelectionChanged = (country) => {
  passportCountry.value = formatData(country);
  modal.value.$el.dismiss();
};

const validateInput = (key) => errors.value && Object.keys(errors.value).includes(key) ? errors.value[key][0] : false;

const forceUppercase = () => {
  passportNumber.value = String(passportNumber.value).toUpperCase();
}

const clearForm = () => {
  errors.value = null;
  isDriversLicenceProvided.value = false;
  isPassportProvided.value = false;
  isMedicareCardProvided.value = false;
  driversLicenceState.value = null;
  driversLicenceNumber.value = null;
  driversLicenceCardNumber.value = null;
  passportNumber.value = null;
  passportCountry.value = passportDefault;
  medicareCardNumber.value = null;
  medicareIndividualReferenceNumber.value = null;
  medicareCardExpiryDate.value = null;
  selectedExpiryDate.value = null;
}

const payloadIdentification = computed(() => ({
  is_drivers_licence_provided: isDriversLicenceProvided.value,
  drivers_licence_number: driversLicenceNumber.value,
  drivers_licence_state: driversLicenceState.value,
  drivers_licence_card_number: driversLicenceCardNumber.value,
  is_passport_provided: isPassportProvided.value,
  passport_number: passportNumber.value,
  passport_country: passportCountry.value,
  is_medicare_provided: isMedicareCardProvided.value,
  medicare_card_number: medicareCardNumber.value,
  medicare_card_expiry: medicareCardExpiryDate.value,
  medicare_card_ref_number: medicareIndividualReferenceNumber.value,
}));

const nextStep = () => {
  axios.post(urls.apiIdentificationDetails, {
    ...payloadIdentification.value,
    device_id: deviceID.value,
    environment: environment.value
  },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
    .then((res) => {
      if (res.status == 200) {
        setCreditScoreIdVerification({ ...payloadIdentification.value })
        router.push({ name: 'CreditScoreIDVerifying' });
        clearForm();
      }
    })
    .catch((err) => {
      scrollToBottom(`section-error-msg`);
      if (err.response.data) {
        errors.value = err.response.data.errors;
      }
    });
}

const getCountriesList = () => {
  if(config.value.countries) {
    countriesList.value = config.value.countries
    .sort(
      (a, b) => {
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0;
      }
    );
  }
}

const closeModal = () => {
  modalController.dismiss();
};

const prefillCheck = () => {
  if (creditScoreIdVerification.value) {
    isDriversLicenceProvided.value = creditScoreIdVerification.value.is_drivers_licence_provided;
    isPassportProvided.value = creditScoreIdVerification.value.is_passport_provided;
    isMedicareCardProvided.value = creditScoreIdVerification.value.is_medicare_provided;
    driversLicenceState.value = creditScoreIdVerification.value.drivers_licence_state;
    driversLicenceNumber.value = creditScoreIdVerification.value.drivers_licence_number;
    driversLicenceCardNumber.value = creditScoreIdVerification.value.drivers_licence_card_number;
    passportNumber.value = creditScoreIdVerification.value.passport_number;
    passportCountry.value = creditScoreIdVerification.value.passport_country;
    medicareCardNumber.value = creditScoreIdVerification.value.medicare_card_number;
    medicareIndividualReferenceNumber.value = creditScoreIdVerification.value.medicare_card_ref_number;
    medicareCardExpiryDate.value = creditScoreIdVerification.value.medicare_card_expiry;
    medicareCardExpiryDateCustom.value = formatCustomExpiryDate(creditScoreIdVerification.value.medicare_card_expiry);
  }
}
onMounted(async () => {
  if (creditScoreIdVerification.value) {
    prefillCheck();
  } else {
    clearForm();
  }
  getCountriesList();
  scrollToBottom(`section-credit-score-id-form-header`);
});

watch(driversLicenceState, () => {
  if (driversLicenceState.value) {
    scrollToBottom(`section-drivers-licence`);
  }
}, { immediate: true });
</script>