<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Date Of Birth</h2>
        <register-steps step="2"></register-steps>
        <div class="ion-item-wrapper ion-item-wrapper-v7 fadein-900" :class="dobPrefill ? 'item-has-prefill' : ''" id="open-modal-datetime">
          <ion-item class="default" lines="none">
            <ion-input label-placement="floating" label="Date Of Birth" :value="dobCustom" class="text-center"></ion-input>
          </ion-item>
        </div>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 ion-text-red block" v-if="dobError">
          {{ dobErrorMsg }}
        </ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 block ion-text-red">{{ validateError }}</ion-note>
        <button class="button-purple button-register my-8 px-6" @click="btnNext('register-step-3')"
          :disabled="dob == '' || dobError">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold text-white" v-else>
            Next <img :src="imgNext" class="float-right ml-2 mt-2" />
          </span>
        </button>
      </div>
    </ion-content>
    <ion-modal class="modal-center modal-fit" ref="modal-datetime" trigger="open-modal-datetime"
      backdrop-dismiss="false">
      <ion-card class="bg-white m-0 p-0 rounded-xl fadein-500">
        <ion-card-content class="m-0 p-0">
          <ion-grid>

            <ion-row>
              <div class="w-full text-right -mb-12">
                <ion-icon src="/assets/general/close-grey.svg"
                  class="m-2 mb-0 w-[16px] h-[16px] cursor-pointer opacity-80 hover:opacity-100"
                  @click="closeModal"></ion-icon>
              </div>
            </ion-row>

            <ion-row>
              <div class="flex items-center justify-center my-4 w-full">
                <ion-icon src="/assets/credit-score/icon-calendar.svg" class="w-[16px] pr-2"></ion-icon>
                <ion-text class="text-lg font-bold ion-text-brand text-center">Select Date</ion-text>
              </div>
              <ion-text class="text-xs ion-text-gray text-center w-full mb-4 px-6" v-if="dobInstruction">
                {{ dobInstruction }}
              </ion-text>
              <ion-text class="text-sm text-center w-full mb-2" v-if="dobCustom">
                You have selected: <strong class="text-md ion-text-purple-3">{{ dobCustom }}</strong>
              </ion-text>
            </ion-row>

            <hr class="my-3" />

            <ion-row class="overflow-scroll">
              <ion-datetime presentation="date" :prefer-wheel="true" id="datetime" v-model="selected"
                @ionChange="updateDOB" class="mx-auto scale-90 min-w-[300px]" color="brand"></ion-datetime>
            </ion-row>

            <hr class="my-3" />

            <ion-row>
              <ion-col>
                <div class="w-full text-xs py-2 ion-text-red block text-center" v-if="dobError">
                  {{ dobErrorMsg }}
                </div>
                <div class="flex justify-center items-center mx-auto">
                  <div class="w-[150px] m-1">
                    <button color="secondary" class="button-purple button-purple-md" :disabled="!dobCustom || dobError"
                      @click="closeModal">
                      <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">Done</span>
                    </button>
                  </div>
                  <div class="w-[150px] m-1" v-if="dobError">
                    <button color="secondary" class="button-purple button-purple-md" @click="clearDOB">
                      <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">Clear</span>
                    </button>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-modal>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import { storeToRefs } from 'pinia';
import { useRegisterStore } from '@/stores';
import axios from 'axios';
import { useMixed } from '@/composables/mixed';
import { useBrowser } from '@/composables/browser';
import { modalController } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
const { urls } = useBrowser();
const { formatDOB, formatCustomDOB, calculateFullAge } = useMixed();
const registerStore = useRegisterStore();
const stepTwoState = storeToRefs(registerStore);
const { setDOB, setStepTwoCompleted } = registerStore;
const dob = ref('');
const dobCustom = ref('');
const dobPrefill = ref(false);
const dobError = computed(() => dob.value && calculateFullAge(dob.value).years < 18 ? true : false);
const dobErrorMsg = ref('You must be older than 18 years old');
const dobInstruction = computed(() => !Capacitor.isNativePlatform() ? 'You can scroll your mouse wheel, or swipe on your track pad, or use arrow keys on your keyboard' : null);
const validated = ref(false);
const validateError = ref('');
const loading = ref(false);
const selected = ref(null);
const imgNext = computed(() => dob.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const clearDOB = () => {
  dob.value = '';
  dobCustom.value = '';
}
const updateDOB = () => {
  dobCustom.value = formatCustomDOB(selected.value);
  dob.value = formatDOB(selected.value);
}
const btnNext = (next) => {
  loading.value = true;
  axios.post(urls.apiSignUpStepDobVerify, {
    date_of_birth: formatDOB(dob.value)
  }).then(res => {
    if (res.status == 200) {
      setDOB(formatDOB(dob.value));
      setTimeout(() => {
        router.push(next);
        setStepTwoCompleted(true);
        loading.value = false;
        validated.value = true;
      }, 1000);
    }
  }).catch(err => {
    loading.value = false;
    validated.value = false;
    validateError.value = err.response.data.message;
  });
}

const prefillCheck = () => {
  if (stepTwoState.dob.value) {
    dob.value = stepTwoState.dob.value;
    dobCustom.value = formatCustomDOB(stepTwoState.dob.value);
    dobPrefill.value = true;
  }
}
const closeModal = () => {
  modalController.dismiss();
};
onMounted(() => {
  if (!stepTwoState.stepOneCompleted.value) {
    router.push('register-step-1')
  }
  prefillCheck();
});
</script>

<style scoped>
input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}
</style>