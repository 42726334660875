<template>
  <ion-header class="ion-no-border" collapse="fade">
    <ion-toolbar color="white">
      <ion-buttons slot="start">
        <ion-button routerLink="/start" v-if="props.start">
          <img src="/assets/general/back.svg" class="inline pl-1 h-[26px]" />
        </ion-button>
        <ion-back-button text="" :class="props.hideBackButton ? 'hidden' : 'pl-3'" icon="/assets/general/back.svg"
          v-else></ion-back-button>
      </ion-buttons>
      <ion-title>
        <app-logo color="purple"></app-logo>
      </ion-title>
      <span slot="end" class="w-[56px] invisible"></span>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import AppLogo from '@/components/start/AppLogo.vue';
const props = defineProps(['start', 'hideBackButton']);
</script>