<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Verify Email</h2>
        <register-steps step="3"></register-steps>
        <ion-text class="text-sm ion-text-grey mt-4 text-center block">Verifying <strong>{{ email }}</strong></ion-text>
        <ion-text class="text-[12px] ion-text-grey my-6 text-center block">
          We have sent a verification code to your email.
          It can take up to <span class="font-bold ion-text-primary">60 seconds</span> to receive the code.
          Enter the code below to verify your email address.
        </ion-text>

        <div class="ion-item-wrapper ion-item-wrapper-v7">
          <ion-item class="default" lines="none">
            <ion-input class="verification-code-input" type="tel" maxlength="4" pattern="[0-9]*" inputmode="numeric"
              label-placement="floating" label="Verification Code" v-model="entered" @input="handleInput"></ion-input>
          </ion-item>
        </div>

        <ion-text class="text-xs ion-text-red text-center block my-6" v-if="match == false">Sorry that
          doesn't appear to be the correct code.</ion-text>

        <ion-text class="font-bold text-sm ion-text-grey my-4 text-center block underline"
          id="open-modal-email-verification-code">Haven't received Code? Click Here</ion-text>

        <ion-modal class="modal-center" ref="modal-verification-code" trigger="open-modal-email-verification-code"
          backdrop-dismiss="false">
          <register-modal :configPhone="configPhone" :configEmail="configEmail" configLabel="email"
            :configUrl="urls.apiSignUpStepEmailResend" @closeModal="closeModal"></register-modal>
        </ion-modal>

        <button class="button-purple button-register mt-4 px-6"
          @click="btnNext('register-step-4', urls.apiSignUpStepEmailVerify)"
          :disabled="!completed || loading || match == false">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold text-white" v-else>
            Next <img :src="imgNext" class="float-right ml-2 mt-2" />
          </span>
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import router from '@/router';
import { modalController } from '@ionic/vue';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import RegisterModal from '@/components/register/RegisterModal.vue';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { useRegisterStore, useConfigStore, useCustomerStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
const { urls } = useBrowser();
const registerStore = useRegisterStore();
const configStore = useConfigStore();
const customerStore = useCustomerStore();
const { email, stepThreeCompleted } = storeToRefs(registerStore);
const { setEmailVerified, setStepThreeVerified, clearPrefill } = registerStore;
const { config, defaultPhone, defaultEmail } = storeToRefs(configStore);
const { deviceID, sessionID, environment } = storeToRefs(customerStore);
const { checkExistingAccount } = customerStore;
const configPhone = computed(() => config.customer_service_phone ? config.customer_service_phone : defaultPhone.value);
const configEmail = computed(() => config.customer_service_email ? config.customer_service_email : defaultEmail.value);
const match = ref(null);
const entered = ref('');
const completed = computed(() => String(verificationCode.value).length == 4);
const verificationCode = computed(() => sanitiseInput(entered.value));
const imgNext = computed(() => completed.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const loading = ref(false);
const sanitiseInput = (val) => String(val).replace(/\D+/g, '');
const handleInput = () => {
  match.value = null;
  setTimeout(() => {
    entered.value = sanitiseInput(entered.value);
  }, 100);
};
const resetInput = () => {
  match.value = null;
  entered.value = null;
}
const focusInput = () => {
  nextTick(() => {
    setTimeout(() => {
      if (document.querySelector('.verification-code-input') !== null) {
        document.querySelector('.verification-code-input input').focus();
      }
    }, 1000);
  });
}
const btnNext = (next, url) => {
  loading.value = true;
  axios.post(url, {
    device_id: deviceID.value,
    session_id: sessionID.value,
    email_verification_code: verificationCode.value,
    environment: environment.value
  })
    .then((res) => {
      loading.value = false;
      if (res.status == 200) {
        match.value = true;
        setEmailVerified(true);
        setStepThreeVerified(true);
        router.push(next);
      }
    })
    .catch((err) => {
      if (err) {
        loading.value = false;
        match.value = false;
        entered.value = null;
        checkExistingAccount(err.response.status, err.response.data.message, clearPrefill);
      }
    });
};
const closeModal = () => {
  modalController.dismiss();
};

onMounted(() => {
  if (!stepThreeCompleted.value) {
    router.push('register-step-1')
  } else {
    focusInput();
    resetInput();
  }
});
</script>