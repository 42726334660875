<template>
  <ion-card class="bg-white m-0 p-0 my-4 rounded-xl credit-score-card credit-score-card-disabled">
    <ion-card-content class="bg-white-500 p-3 pb-0">
      <ion-grid>
        <ion-row class="mb-2">
          <ion-col class="py-0" size="1">
            <ion-icon src="/assets/credit-score/icon-calendar.svg" class="w-[16px]"></ion-icon>
          </ion-col>
          <ion-col class="py-0 text-center">
            <ion-text class="ion-text-purple block font-bold text-sm">{{ props.title }}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
const props = defineProps(['title']);
</script>