<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[22px] font-bold pt-9 pb-2">Choose a 4 Digit Passcode</h2>
        <p class="text-sm pb-3">This will be used to login to your Jacaranda Finance account.</p>
        <numeric-keypad ref="setPasscodeRef" type="set" length="4" :completed="completed" @keypadChange="keypadChange"
          @keypadComplete="keypadComplete" />
      </div>
    </ion-content>
    <ion-footer class="shadow-none">
      <div class="px-8 pb-6">
        <div v-if="completed">
          <button class="button-purple mb-6" @click="keypadContinue" :disabled="!completed">
            <span class="block capitalize text-white text-lg font-semibold">Continue</span>
          </button>
        </div>
        <div class="text-center text-base mb-6 square">
          <button fill="clear" @click="keypadSkip">
            <span class="ion-text-primary font-bold underline">Skip</span>
          </button>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { ref, computed } from 'vue';
import router from '@/router';
import { asyncComputed } from '@vueuse/core';
import StartHeader from '@/components/start/StartHeader.vue';
import NumericKeypad from '@/components/misc/NumericKeypad.vue';
import { useSecureStorage } from '@/composables/secureStorage';
import { useBiometryAuth } from '@/composables/biometryAuth';
const { keys, setStorage, getStorage, removeStorage } = useSecureStorage();
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const { biometryAvailCheck } = useBiometryAuth();
const entered = ref(null);
const completed = ref(false);
const nextPage = computed(() => biometryAvailCheck.value ? 'set-bio' : 'home');
const keypadChange = (v) => {
  completed.value = false;
  entered.value = v;
};
const keypadComplete = (v) => {
  completed.value = true;
  entered.value = v;
}
const keypadContinue = () => {
  setStorage({ key: keys.otp, value: entered.value });
  router.push(nextPage.value);
};
const keypadSkip = () => {
  if (otpStorage.value) {
    removeStorage({ key: keys.otp });
  }
  router.push(nextPage.value);
};
</script>