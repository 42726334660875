<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[30px] font-bold py-9">Reset Password</h2>
        <p class="mb-9">Please enter the verification code that was sent to your phone or email address.</p>
        <div class="ion-item-wrapper ion-item-wrapper-v7">
          <ion-item class="default" lines="none">
            <ion-input label-placement="floating" label="Verification Code" class="verification-code-input" type="tel" maxlength="6" pattern="[0-9]*" inputmode="numeric"
              v-model="codeEntered" @input="handleCodeInput"></ion-input>
          </ion-item>
        </div>
        <div class="mt-6" :class="codeCompleted ? 'fadein-900' : ''" v-if="codeCompleted">
          <div class="ion-item-wrapper ion-item-wrapper-v7">
            <ion-item class="default" lines="none">
              <ion-input label-placement="floating" label="Password" placeholder="New Password" type="password" ref="inputPassword" v-model="password"
                @input="handleInputPassword" @ionChange="handleInputPassword"></ion-input>
              <ion-img src="/assets/login/eye.svg" class="absolute top-6 right-4 z-10"
                :class="showPassword ? 'opacity-100' : 'opacity-40'" @click="toggleShowPassword"
                v-if="password"></ion-img>
            </ion-item>
          </div>
          <ion-note slot="helper" class="w-full text-[11px] p-3 mt-3 -mb-3 rounded block text-notice-red bg-notice-red"
            v-if="passwordError">{{ passwordError }}</ion-note>

          <div class="ion-item-wrapper ion-item-wrapper-v7 mt-6" :class="passwordError || !validated ? 'hidden' : ''">
            <ion-item class="default" lines="none">
              <ion-input label-placement="floating" label="Confirm Password" placeholder="Confirm Password" type="password" ref="inputPasswordConfirmed"
                v-model="passwordConfirmed" @input="handleInputPasswordConfirmed"
                @ionChange="handleInputPasswordConfirmed" :disabled="!validated"></ion-input>
              <ion-img src="/assets/login/eye.svg" class="absolute top-6 right-4 z-10"
                :class="showPasswordConfirmed ? 'opacity-100' : 'opacity-40'" @click="toggleShowPasswordConfirmed"
                v-if="passwordConfirmed"></ion-img>
            </ion-item>
          </div>
          <ion-note slot="helper" class="w-full text-[11px] p-3 mt-3 -mb-3 rounded block text-notice-red bg-notice-red"
            v-if="matchError">{{ matchError }}</ion-note>
        </div>

        <ion-note slot="helper" class="w-full text-[11px] p-3 mt-3 -mb-3 rounded block text-notice-red bg-notice-red"
          v-if="sendError">
          <span v-html="sendError"></span>
        </ion-note>

        <div class="mt-3" :class="codeCompleted ? 'fadein-900' : ''" v-if="confirmed && validated">
          <button class="button-purple mt-6 mb-8 px-6" @click="changePassword(urls.apiRecoverPassword)">
            <span class="block text-center" v-if="loading">
              <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
            </span>
            <span class="block capitalize text-white text-lg font-semibold" v-else>Change Password</span>
          </button>
        </div>
      </div>

      <ion-modal class="modal-center" ref="modal-reset-password" backdrop-dismiss="false" :is-open="showModal">
        <ion-card class="bg-white m-0 p-0 rounded-xl">
          <ion-card-content class="px-4 pt-3 pb-2">
            <ion-grid>
              <ion-row>
                <ion-col class="py-0 text-center">
                  <ion-text class="ion-text-green block font-bold text-lg mb-3">
                    Password Updated!
                  </ion-text>
                  <ion-text class="ion-text-purple block text-sm mb-3">
                    Your password has been changed successfully.
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                    @click="dismissModal()">
                    <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
                  </button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import StartHeader from '@/components/start/StartHeader.vue';
import { useField } from 'vee-validate';
import { useBrowser } from '@/composables/browser';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { modalController } from '@ionic/vue';
import { useCustomerStore, useNotificationsStore, useLoansStore, useConfigStore, useApplicationsStore } from '@/stores';
import { addCustomerIdToSession } from "@/composables/adjust";
import { useMixed } from '@/composables/mixed';
const { fieldValidation } = useMixed();
const customerStore = useCustomerStore();
const { updateAuth, setAuthToken, setEnvironment, setProfile, updateNewCustomer, setName } = customerStore;
const notificationsStore = useNotificationsStore();
const { setNotifications } = notificationsStore;
const configStore = useConfigStore();
const { setConfig } = configStore;
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { deviceID, emailForgotPassword, emailForgotPasswordCompleted, tokenForgotPassword, authToken, getCustomerParams } = storeToRefs(customerStore);
const { urls } = useBrowser();
const codeEntered = ref('');
const codeCompleted = computed(() => String(verificationCode.value).length == 6);
const verificationCode = computed(() => sanitiseInput(codeEntered.value));
const sanitiseInput = (val) => String(val).replace(/\D+/g, '');
const inputPassword = ref();
const inputPasswordConfirmed = ref();
const showPassword = ref(false);
const showPasswordConfirmed = ref(false);
const loading = ref(false);
const showModal = ref(false);
const sendError = ref('');
const passwordConfirmed = ref('');
const entered = computed(() => password.value !== '' && passwordConfirmed.value !== '');
const confirmed = computed(() => password.value == passwordConfirmed.value);
const matchError = computed(() => entered.value && !confirmed.value ? fieldValidation.password.msgMatch : '');
const validated = ref(false);
const handleCodeInput = () => {
  setTimeout(() => {
    codeEntered.value = sanitiseInput(codeEntered.value);
    passwordConfirmed.value = '';
    sendError.value = '';
  }, 100);
};
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputPassword.value.$el.type = showPassword.value ? 'text' : 'password';
}
const toggleShowPasswordConfirmed = () => {
  showPasswordConfirmed.value = !showPasswordConfirmed.value;
  inputPasswordConfirmed.value.$el.type = showPasswordConfirmed.value ? 'text' : 'password';
}
const toggleReset = () => {
  showPassword.value = false;
  showPasswordConfirmed.value = false;
  inputPassword.value.$el.type = 'password';
  inputPasswordConfirmed.value.$el.type = 'password';
}
const handleInputPassword = () => {
  passwordConfirmed.value = '';
  sendError.value = '';
  toggleReset();
}
const handleInputPasswordConfirmed = () => {
  sendError.value = '';
  toggleReset();
}
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value) {
    validated.value = false;
    sendError.value = '';
    return fieldValidation.password.msgRequire;
  }
  const regex = fieldValidation.password.regex;
  if (!regex.test(String(value)) || value.length < 6) {
    validated.value = false;
    return fieldValidation.password.msgValidate;
  } else {
    validated.value = true;
    return true;
  }
});
const rendErrMsg = (err) => err.response.data.errors ? Object.values(err.response.data.errors).map(e => `<p>${e}</p>`).join('') : err.response.data.message;
const changePassword = (url) => {
  loading.value = true;
  axios.post(url, {
    email: emailForgotPassword.value,
    code: verificationCode.value,
    token: tokenForgotPassword.value,
    password: password.value,
    password_confirmation: passwordConfirmed.value,
    device_id: deviceID.value
  })
    .then(res => {
      if (res.status == 200) {
        setEnvironment(res.data.environment);
        setAuthToken(res.data.auth_token);
        updateAuth(true);
        setTimeout(() => {
          loading.value = false;
          validated.value = true;
          showModal.value = true;
        }, 1000);
      } else {
        loading.value = false;
        validated.value = false;
        sendError.value = rendErrMsg(err);
      }
    }).catch(err => {
      loading.value = false;
      validated.value = false;
      sendError.value = rendErrMsg(err);
    });
};

const dismissModal = () => {
  setTimeout(() => {
    axios.get(urls.apiCustomer + getCustomerParams.value, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
      .then((res) => {
        setProfile(res.data.customer);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
        setName(res.data.customer.first_name);
        setConfig(res.data.config);
        updateNewCustomer(false);
        addCustomerIdToSession(res.data.customer.id);
        modalController.dismiss();
        router.push('home');
      })
      .catch(err => console.log(err));
  }, 3000);
}

const focusInput = () => {
  nextTick(() => {
    setTimeout(() => {
      if (document.querySelector('.verification-code-input') !== null) {
        document.querySelector('.verification-code-input input').focus();
      }
    }, 1000);
  });
}

onMounted(() => {
  if (!emailForgotPasswordCompleted.value) {
    router.push('forgot-password');
  } else {
    focusInput();
  }
});

</script>