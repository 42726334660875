<template>
  <ion-card class="m-0 p-0 mb-6 rounded-xl drop-shadow-none fadein-600" :class="`bg-notice-${props.type}`">
    <ion-card-content class="p-1 px-3">
      <ion-grid>
        <ion-row>
          <ion-col size="1">
            <ion-icon :src="`/assets/general/notice-${props.type}.svg`" class="w-[18px] h-[18px] pt-1"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-text class="font-bold text-sm m-2 mt-1 pr-4 block text-left" :class="`text-notice-${props.type}`">
              Your enrolment is currently pending. Please check back later.
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>
<script setup>
const props = defineProps(['type']);
</script>